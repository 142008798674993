import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space, Form, Input, InputNumber } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import Seo from "../Seo"
import Office from "./Office"
import ContactImg from "../../images/contact-us.png"
import './contactus.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */


export default function ContactUs({ title, description }) {

  let location = null
  if (typeof window !== `undefined`) {
    location = window.location
  }

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Content className='contactus-root'>
      <Seo
        title={title}
        description={description}
        // image={`http:${post.heroImage.resize.src}`}
        type="Organization"
        origin={location != null ? location.origin : null}
        pathname={location != null ? location.pathname : null}
      />
      <Col className='contactus-container' >
        <Row style={{ alignItems: 'center' }}>
          <Typography>
            <Title className="contactus-title">Contact us</Title>
            <Paragraph className='contactus-subtitle'>
              Any questions or remarks? We'll get back you within 24 hours.
            </Paragraph>
          </Typography>
        </Row>
        <Row style={{ paddingTop: 24, justifyContent: "flex-start" }}>
          <Row className="addresses">
            <Office
              name="Registred office"
              address="427, Bal Vihar Greens, Sonari, Jamshedpur, India 831011"
              email="hello@fieldnext.io"
              phone="+91 81789 72150"
            />
            {/* <Office
              name="USA office"
              address="7359 Water Oaks Dr. Westbloomfield, MI 48324"
              email="kumar@fieldnext.io"
              phone="+1 (313) 920-5235"
            /> */}
          </Row>
          <Col className="image">
            <Image
              width={450}
              src={ContactImg}
              preview={false}
            />
          </Col>
          <Col className="form" >
            <div className="form-header">Contact form</div>
            <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
              <Form.Item name={['user', 'name']} label="Name" rules={[{ required: true }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email', required: true }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item name={['user', 'website']} label="Website">
                <Input size="large" />
              </Form.Item>
              <Form.Item name={['user', 'subject']} label="Subject" rules={[{ required: true }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item name={['user', 'message']} label="Message" rules={[{ required: true }]}>
                <Input.TextArea size="large" />
              </Form.Item>
              <Form.Item style={{ float: "right" }}>
                <Button type="primary" htmlType="submit" size="large" style={{ marginTop: 16 }}>
                  Send message
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Content>
  )
}