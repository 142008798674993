import React from 'react'
import Layout from "../components/Layout"
import ContactUs from "../components/ContactUs"
import {Col, Divider} from 'antd'

export default function index() {
  const title="FieldNext - Contact us"
  const description="FieldNext contacts for the latest mobile-enabled software for field employee tracking, monitoring, reporting, attendance, expense, visit, and task management."

  return <Layout>
    <ContactUs
      title={title}
      description={description}
    />
    <Divider/>
  </Layout>
}
