import React from 'react'
import {EnvironmentTwoTone, PhoneTwoTone, MailTwoTone, setTwoToneColor} from '@ant-design/icons';

const styles = {
  name: {
    fontFamily: "Futura Bold",
    textTransform:"uppercase",
    FontSize: 32,
    marginBottom: 4
  },
  lineItem: {
    // fontFamily: "Futura Medium",
    display: "flex",
    alignItems: "flex-start",
    padding: "2px 0px"
  },
  icon: {
    marginRight: 8,
    color: "#c5220f",
    fontSize: 18,
    paddingTop: 4
  }
}

export default function Office({name, address, email, phone}) {
  setTwoToneColor('#c5220f');

  return (
    <div xl={24} className='office'>
      <div style={styles.name}>{name}</div>
      <div style={styles.lineItem}><EnvironmentTwoTone style={styles.icon}/>{address}</div>
      <div style={styles.lineItem}><MailTwoTone style={styles.icon}/>{email}</div>
      <div style={styles.lineItem}><PhoneTwoTone style={{...styles.icon, transform: "rotateY(180deg)"}}/>{phone}</div>
    </div>
  )
}
